/**
* Template Name: FlexStart
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;

}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding-top: 100px;
  background: #012970;
  min-height: 40px;
padding-bottom: 0px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  background-color: white;
}

.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
 
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #012970;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.hero .btn-get-started span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
  background-color: #f6f9ff;
  padding: 40px;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #4154f1;
  text-transform: uppercase;
}

.about h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  text-align: center;
  transition: 0.3s;
  height: 100%;
}

.values .box img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .box h3 {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
  transform: scale(1);
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  display: flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: #4154f1;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #0b198f;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #4154f1;
  color: #fff;
}

.features .feture-tabs {
  margin-top: 120px;
}

.features .feture-tabs h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .features .feture-tabs h3 {
    font-size: 28px;
  }
}

.features .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.features .feture-tabs .nav-link.active {
  color: #4154f1;
  border-bottom: 3px solid #4154f1;
}

.features .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}

.features .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #4154f1;
}

.features .feature-icons {
  margin-top: 120px;
}

.features .feature-icons h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .service-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
}

.services .service-box .icon {
  font-size: 36px;
  padding: 40px 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
  line-height: 0;
  transition: 0.3s;
}

.services .service-box h3 {
  color: #444444;
  font-weight: 700;
}

.services .service-box .read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
}

.services .service-box .read-more i {
  line-height: 0;
  margin-left: 5px;
  font-size: 18px;
}

.services .service-box.blue {
  border-bottom: 3px solid #2db6fa;
}

.services .service-box.blue .icon {
  color: #2db6fa;
  background: #dbf3fe;
}

.services .service-box.blue .read-more {
  color: #2db6fa;
}

.services .service-box.blue:hover {
  background: #2db6fa;
}

.services .service-box.orange {
  border-bottom: 3px solid #f68c09;
}

.services .service-box.orange .icon {
  color: #f68c09;
  background: #fde3c4;
}

.services .service-box.orange .read-more {
  color: #f68c09;
}

.services .service-box.orange:hover {
  background: #f68c09;
}

.services .service-box.green {
  border-bottom: 3px solid #08da4e;
}

.services .service-box.green .icon {
  color: #08da4e;
  background: #cffddf;
}

.services .service-box.green .read-more {
  color: #08da4e;
}

.services .service-box.green:hover {
  background: #08da4e;
}

.services .service-box.red {
  border-bottom: 3px solid #e9222c;
}

.services .service-box.red .icon {
  color: #e9222c;
  background: #fef7f8;
}

.services .service-box.red .read-more {
  color: #e9222c;
}

.services .service-box.red:hover {
  background: #e9222c;
}

.services .service-box.purple {
  border-bottom: 3px solid #b50edf;
}

.services .service-box.purple .icon {
  color: #b50edf;
  background: #f8e4fd;
}

.services .service-box.purple .read-more {
  color: #b50edf;
}

.services .service-box.purple:hover {
  background: #b50edf;
}

.services .service-box.pink {
  border-bottom: 3px solid #f51f9c;
}

.services .service-box.pink .icon {
  color: #f51f9c;
  background: #feecf7;
}

.services .service-box.pink .read-more {
  color: #f51f9c;
}

.services .service-box.pink:hover {
  background: #f51f9c;
}

.services .service-box:hover h3,
.services .service-box:hover p,
.services .service-box:hover .read-more {
  color: #fff;
}

.services .service-box:hover .icon {
  background: #fff;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 40px 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.pricing .box:hover {
  transform: scale(1.1);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.pricing .price {
  font-size: 36px;
  color: #444444;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .price span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing img {
  padding: 30px 40px;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.pricing ul li {
  padding-bottom: 10px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: #4154f1;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid #4154f1;
}

.pricing .btn-buy:hover {
  background: #4154f1;
  color: #fff;
}

.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #4154f1;
  color: #fff;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 15px 20px 0;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: #444444;
  text-align: left;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #4154f1;
  border-bottom: 0;
}

.faq .accordion-body {
  padding: 0 0 25px 0;
  border: 0;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
  
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: 0.3s;
  border-radius: 50px;
  border: 1px solid #fff;
 
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #4154f1;
  border-color: #4154f1;

}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background: rgba(255, 255, 255, 0.75);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: 1s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid rgba(1, 41, 112, 0.2);
  border-left: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid rgba(1, 41, 112, 0.2);
  border-right: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #012970;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  background: #4154f1;
  margin: 10px 2px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a i {
  font-size: 24px;
  line-height: 0;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #5969f3;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}



/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
  z-index: -1;
}

.team .member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .member-img:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url(./images/team-shape.svg) no-repeat center bottom;
  background-size: contain;
  z-index: 1;
}

.team .member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}

.team .member .social a {
  transition: color 0.3s;
  color: rgba(1, 41, 112, 0.5);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}

.team .member .social a:hover {
  color: rgba(1, 41, 112, 0.8);
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 10px 15px 20px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #012970;
}

.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: #5e5e5e;
}

.team .member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.team .member:hover .social {
  right: 8px;
  opacity: 1;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-slider .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .clients-slider .swiper-slide img:hover {
  opacity: 1;
}

.clients .clients-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
  
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: #4154f1;
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: rotate(6deg) scale(1.2);
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  background: #fafbff;
  padding: 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fafbff;
  padding: 30px;
  height: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email] {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;

}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #012970;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #4154f1;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #4084fd;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #4154f1;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #5969f3;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #012970;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #0d64fd;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #013289;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #4154f1;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #012970;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(1, 41, 112, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #4154f1;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #012970;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #013ca3;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #012970;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #013289;
}

.blog .blog-pagination {
  color: #024ed5;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #012970;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #4154f1;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #012970;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #5465f2;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #012970;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #4154f1;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #012970;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #4154f1;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #0257ee;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #d7e6ff;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #4154f1;
  background: #4154f1;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #a5c5fe;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #f6f9ff;
  padding: 0 0 30px 0;
  font-size: 14px;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #012970;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type=submit]:hover {
  background: #5969f3;
}



@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #012970;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #012970;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #013289;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #4154f1;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #012970;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}
Link{
  text-decoration: none;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.portfolio-box::before,
.portfolio-box::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    transition: .5s;
    z-index: 1;
    background: rgba(4, 15, 40, .7);
}

.portfolio-box::before {
    left: 0;
}

.portfolio-box::after {
    right: 0;
}

.portfolio-item:hover .portfolio-box::before {
    left: 0;
    width: 50%;
}

.portfolio-item:hover .portfolio-box::after {
    right: 0;
    width: 50%;
}
.portfolio-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 30px;
  right: 30px;
  left: 30px;
  bottom: -60px;
  background: #FFFFFF;
  z-index: 3;
}
.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.blog .blog-item .blog-img {
  position: relative;
}

.blog .blog-item .blog-img .blog-info {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  background: rgba(255, 255, 255, .2);
  color: var(--bs-white);
}

.blog .blog-item .blog-img .blog-img-inner {
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.blog .blog-item .blog-img .blog-img-inner .blog-icon {
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.blog .blog-item .blog-img .blog-img-inner .blog-icon a {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover .blog-img-inner .blog-icon a {
  opacity: 1;
}

.blog .blog-item .blog-img:hover .blog-img-inner .blog-icon {
  height: 100%;
  background: rgba(19, 53, 123, .6);
  opacity: 1;
}

.blog .blog-item .blog-img .blog-img-inner {
  overflow: hidden;
}

.blog .blog-item .blog-img .blog-img-inner img {
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover .blog-img-inner img {
  transform: scale(1.2);
}
/*** Blog End ***/

/*** Testimonial Start ***/
.testimonial .testimonial-carousel {
  position: relative;
}


.page-headerr {
    background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(https://th.bing.com/th/id/R.2ed96b15481a656c4815f7040c4bfddb?rik=0a98pw70xo7hBg&riu=http%3a%2f%2fkardiaholdings.co.zw%2fbulawayo%2fimages%2fmain-slider%2fimage-2.jpg&ehk=LYj1t3h1Xqmx46swrbTQeHEk6iJlrESGM8Bl6Bp4wCE%3d&risl=&pid=ImgRaw&r=0) center center no-repeat;
    background-size: cover;
    color: white;
    padding-top: 20px;
    
}

.session-title {
  padding: 30px;
  margin: 0px;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .session-title {
      padding: 5px; } }
  .session-title h2 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px; }
  .session-title p {
    max-width: 850px;
    text-align: center;
    float: none;
    margin: auto;
    font-size: .9rem;
    margin-top: 6px; }
  .session-title span {
    float: right;
    font-style: italic; }




    .chat-search-box {
      -webkit-border-radius: 3px 0 0 0;
      -moz-border-radius: 3px 0 0 0;
      border-radius: 3px 0 0 0;
      padding: .75rem 1rem;
  }
  
  .chat-search-box .input-group .form-control {
      -webkit-border-radius: 2px 0 0 2px;
      -moz-border-radius: 2px 0 0 2px;
      border-radius: 2px 0 0 2px;
      border-right: 0;
  }
  
  .chat-search-box .input-group .form-control:focus {
      border-right: 0;
  }
  
  .chat-search-box .input-group .input-group-btn .btn {
      -webkit-border-radius: 0 2px 2px 0;
      -moz-border-radius: 0 2px 2px 0;
      border-radius: 0 2px 2px 0;
      margin: 0;
  }
  
  .chat-search-box .input-group .input-group-btn .btn i {
      font-size: 1.2rem;
      line-height: 100%;
      vertical-align: middle;
  }
  
  @media (max-width: 767px) {
      .chat-search-box {
          display: none;
      }
      
  }
  
  
  /************************************************
    ************************************************
                    Users Container
    ************************************************
  ************************************************/
  
  .users-container {
      position: relative;
      padding: 1rem 0;
      border-right: 1px solid #e6ecf3;
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: black;
      color: white;
  }
  
  
  /************************************************
    ************************************************
                        Users
    ************************************************
  ************************************************/
  
  .users {
      padding: 0;
      margin-left: 20px;
      
    
  }
  
  .users .person {
    
      position: relative;
      width: 95%;
      padding: 10px 1px;
      cursor: pointer;
     font-weight: bolder;
      border-bottom: 1px solid #f0f4f8;
  }
  .del{
    display: flex;
      justify-content: end;
  
  }
  .users .person:hover {
      background-color: pink;
      /* Fallback Color */
      background-image: -webkit-gradient(linear, left top, left bottom, from(#e9eff5), to(#ffffff));
      /* Saf4+, Chrome */
      background-image: -webkit-linear-gradient(right, #e9eff5, #ffffff);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background-image: -moz-linear-gradient(right, #e9eff5, #ffffff);
      /* FF3.6 */
      background-image: -ms-linear-gradient(right, #e9eff5, #ffffff);
      /* IE10 */
      background-image: -o-linear-gradient(right, #e9eff5, #ffffff);
      /* Opera 11.10+ */
      background-image: linear-gradient(right, #e9eff5, #ffffff);
  }
  
  .users .person.active-user {
      background-color: #ffffff;
      /* Fallback Color */
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f9fb), to(#ffffff));
      /* Saf4+, Chrome */
      background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
      /* FF3.6 */
      background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
      /* IE10 */
      background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
      /* Opera 11.10+ */
      background-image: linear-gradient(right, #f7f9fb, #ffffff);
  }
  
  .users .person:last-child {
      border-bottom: 0;
     
  }
  
  .users .person .user {
      display: inline-block;
      position: relative;
      margin-right: 10px;
    
  }
  
  .users .person .user img {
      width: 48px;
      height: 48px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
  }
  
  .users .person .user .status {
      width: 10px;
      height: 10px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      background: #0a4486;
      position: absolute;
      top: 0;
      right: 0;
  }
  
  .users .person .user .status.online {
      background: #9ec94a;
  }
  
  .users .person .user .status.offline {
      background: #c4d2e2;
  }
  
  .users .person .user .status.away {
      background: #f9be52;
  }
  
  .users .person .user .status.busy {
      background: #fd7274;
  }
  
  .users .person p.name-time {
      font-weight: 600;
      font-size: .85rem;
      display: inline-block;
  }
  
  .users .person p.name-time .time {
      font-weight: 400;
      font-size: .7rem;
      text-align: right;
      color: #8796af;
  }
  
  @media (max-width: 767px) {
      .users .person .user img {
          width: 30px;
          height: 30px;
      }
      .users .person p.name-time {
          display: none;
      }
      .users .person p.name-time .time {
          display: none;
      }
      
  }
  
  
  /************************************************
    ************************************************
                    Chat right side
    ************************************************
  ************************************************/
  
  .selected-user {
      width: 100%;
      padding: 0 15px;
      min-height: 64px;
      line-height: 64px;
      border-bottom: 1px solid #e6ecf3;
      -webkit-border-radius: 0 3px 0 0;
      -moz-border-radius: 0 3px 0 0;
      border-radius: 0 3px 0 0;
  }
  
  .selected-user span {
      line-height: 100%;
  }
  
  .selected-user span.name {
      font-weight: 700;
  }
  
  .chat-container {
      position: relative;
      padding: 1rem;
  }
  
  .chat-container li.chat-left,
  .chat-container li.chat-right {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 40px;
  }
  
  .chat-container li img {
      width: 48px;
      height: 48px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
  }
  
  .chat-container li .chat-avatar {
      margin-right: 20px;
  }
  
  .chat-container li.chat-right {
      justify-content: flex-end;
  }
  
  .chat-container li.chat-right > .chat-avatar {
      margin-left: 20px;
      margin-right: 0;
  }
  
  .chat-container li .chat-name {
      font-size: .75rem;
      color: #999999;
      text-align: center;
  }
  
  .chat-container li .chat-text {
      padding: .4rem 1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #ffffff;
      font-weight: 300;
      line-height: 150%;
      position: relative;
      width: 65%;
  }
  
  .chat-container li .chat-text:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 10px;
      left: -20px;
      border: 10px solid;
      border-color: transparent #ffffff transparent transparent;
  }
  
  .chat-container li.chat-right > .chat-text {
      text-align: left;
  }
  
  .chat-container li.chat-right > .chat-text:before {
      right: -20px;
      border-color: transparent transparent transparent #ffffff;
      left: inherit;
  }
  
  .chat-container li .chat-hour {
      padding: 0;
      margin-bottom: 10px;
      font-size: .75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 15px;
  }
  
  .chat-container li .chat-hour > span {
      font-size: 16px;
      color: #9ec94a;
  }
  
  .chat-container li.chat-right > .chat-hour {
      margin: 0 15px 0 0;
  }
  
  
  @media (max-width: 767px) {
    .chat-container li .chat-text {
      padding: .4rem 1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #ffffff;
      font-weight: 300;
      line-height: 150%;
      position: relative;
width: 100%;
    
  }
      .chat-container li.chat-left,
      .chat-container li.chat-right {
          flex-direction: column;
          margin-bottom: 30px;
      }
      .del{
      display: flex;
      justify-content: end;
      }
      .chat-container li img {
          width: 32px;
          height: 32px;
      }
      .chat-container li.chat-left .chat-avatar {
          margin: 0 0 5px 0;
          display: flex;
          align-items: center;
      }
      .chat-container li.chat-left .chat-hour {
          justify-content: flex-end;
      }
      .chat-container li.chat-left .chat-name {
          margin-left: 5px;
      }
      .chat-container li.chat-right .chat-avatar {
          order: -1;
          margin: 0 0 5px 0;
          align-items: center;
          display: flex;
          justify-content: right;
          flex-direction: row-reverse;
      }
      .chat-container li.chat-right .chat-hour {
          justify-content: flex-start;
          order: 2;
      }
      .chat-container li.chat-right .chat-name {
          margin-right: 5px;
      }
      .chat-container li .chat-text {
          font-size: .8rem;
      }
  }
  
  .chat-form {
      padding: 15px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      border-top: 1px solid white;
  }
  
  ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
  }
  .card {
      border: 0;
      background: #f4f5fb;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      margin-bottom: 2rem;
      box-shadow: none; 
      padding:0 20px ;
      height: 100%;
  }.auth .brand-logo {
    margin-bottom: 2rem;
  }
  .auth .brand-logo img {
    width: 100%;
 
  }
  .section {
    padding: 100px 0;
  }
  
  .section-sm {
    padding: 70px 0;
  }
  
  .section-title {
    margin-bottom: 70px;
  }
  
  .section-title .title {
    font-size: 50px;
    line-height: 50px;
  }
  
  .section-title p {
    color: #666;
    font-family: "Poppins", sans-serif;
  }
  .comments-section {
    margin-top: 35px;
  }
  .blog-item-meta span {
    margin: 6px 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .blog-item-content h3 {
    line-height: 36px;
  }
  .blog-item-content h3 a {
    transition: all .4s ease 0s;
  }
  .blog-item-content h3 a:hover {
    color: #f75757 !important;
  }

  
.blog-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.blog-item-content h3 {
  line-height: 36px;
}

.blog-item-content h3 a {
  transition: all .4s ease 0s;
}

.blog-item-content h3 a:hover {
  color: #f75757 !important;
}
.blog-item-meta span {
  margin: 6px 0px;
}
.blog-item-meta span {
  display: block;
  margin: 6px 0px;
}
.blog-item-meta span {
  display: block;
  margin: 6px 0px;
}
.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #f75757;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #f75757;
  border: 1px solid #f75757;
}
.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}
.nanaa{
 width: 120px;

}
.pic{
  width: 100%;
  height: 120px;
}
.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
.image404{
  width: 100%;
  height: 400px;
}
.cascading-right {
  margin-right: -50px;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 0;
  }
}.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  background: #ffffff;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #fdbe33;
  color: #121518;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: #fdbe33;
  background: #121518;
}

.back-to-top i {
  padding-top: 10px;
}

.btn {
  transition: .3s;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: relative;
  height: 90px;
  background: #fdbe33;
}

.top-bar .logo {
  padding: 15px 0;
  text-align: left;
  overflow: hidden;
}

.top-bar .logo h1 {
  margin: 0;
  color: #030f27;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
}

.top-bar .logo img {
  max-width: 100%;
  max-height: 72px;
}

.top-bar .top-bar-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .top-bar-icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .top-bar-icon [class^="flaticon-"]::before {
  margin: 0;
  color: #030f27;
  font-size: 40px;
}

.top-bar .top-bar-text {
  padding-left: 15px;
}

.top-bar .top-bar-text h3 {
  margin: 0 0 5px 0;
  color: #030f27;
  font-size: 16px;
  font-weight: 400;
}

.top-bar .top-bar-text p {
  margin: 0;
  color: #030f27;
  font-size: 13px;
  font-weight: 400;
}

@media (min-width: 992px) {
  .top-bar {
      padding: 0 60px;
  }
}

@media (max-width: 991.98px) {
  .top-bar .logo {
      text-align: center;
  }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.nav-bar {
  position: fixed;
  background: #ffffff;
  transition: .3s;
  width: 100%;
  z-index: 1;
}.nav-bar1 {
  
  background: #ffffff;
  transition: .3s;
  width: 100%;
  z-index: 1;
}


.nav-bar .container-fluid {
  padding: 0;
}

.nav-bar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1366px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
  z-index: 999;
}

.nav-bar .navbar {
  height: 100%;
  background: #050505 !important;
  
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #fcfafa;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ebaaaa;
  transition: none;
}

.nav-bar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #000000;
}

.nav-bar .btn {
  color: #f3eeee;
  border: 2px solid #ffffff;
  border-radius: 0;
  background-color: #000000;
  border-radius: 5px;
}

.nav-bar .btn:hover {
  color: #f9f9f9;
  background: #2f14b5;
  border-color: #2811ac;
}
.loo{
  color: #ffffff;
  

  }
@media (min-width: 992px) {
  .nav-bar .navbar {
    height: 100%;
    background: #ffffff !important;
   
    
  }


  .loo{
  color: #0c1a7b;
  margin-left: 50px;
  }
  .nab{

    margin-left: 250px;
  }
  
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link.active {
    padding: 10px 10px 8px 10px;
    color: #000000;
  }
  
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #ebaaaa;
    transition: none;
  }
  
  .nav-bar.nav-sticky {
      padding: 0;
  }
  
  .nav-bar .navbar {
      padding: 20px;
  }
  
  .nav-bar .navbar-brand {
      
  }
  
  .nav-bar a.nav-link {
      padding: 8px 15px;
      font-size: 15px;
      text-transform: uppercase;
  }
}

@media (max-width: 991.98px) {
  .nav-bar .navbar {
      padding: 15px;
  }
  
  .nav-bar a.nav-link {
      padding: 5px;
  }
  
  .nav-bar .dropdown-menu {
      box-shadow: none;
  }
  
  .nav-bar .btn {
      display: none;
  }
}.carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.carouselt {
  position: relative;
  width: 100%;
  height: calc(100vh - 90px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-inner,
.carousel .carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 1;
}

.carousel .carousel-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 170px);
  min-height: 400px;
}

.carousel .carousel-caption p {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.carousel .carousel-caption h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 35px;
}

.carousel .carousel-caption .btn {
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 0;
  transition: .3s;
}

.carousel .carousel-caption .btn:hover {
  color: #030f27;
  background: #fdbe33;
  border-color: #fdbe33;
}

@media (max-width: 767.98px) {
  .carousel .carousel-caption h1 {
      font-size: 40px;
      font-weight: 700;
  }
  
  .carousel .carousel-caption p {
      font-size: 20px;
  }
  
  .carousel .carousel-caption .btn {
      padding: 12px 30px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-caption h1 {
      font-size: 30px;
      font-weight: 500;
  }
  
  .carousel .carousel-caption p {
      font-size: 16px;
  }
  
  .carousel .carousel-caption .btn {
      padding: 10px 25px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
  }
}

.carousel .animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}







.feature {
  position: relative;
  margin-bottom: 45px;
}

.feature .col-md-12 {
  background: #030f27;
}
  
.feature .col-md-12:nth-child(2n) {
  color: #030f27;
  background: #fdbe33;
}

.feature .feature-item {
  min-height: 250px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feature .feature-icon {
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature .feature-icon::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  top: -20px;
  left: -10px;
  border: 2px dotted #ffffff;
  border-radius: 60px;
  z-index: 1;
}

.feature .feature-icon::after {
  position: absolute;
  content: "";
  width: 79px;
  height: 79px;
  top: -18px;
  left: -9px;
  background: #030f27;
  border-radius: 60px;
  z-index: 2;
}

.feature .col-md-12:nth-child(2n) .feature-icon::after {
  background: #fdbe33;
}

.feature .feature-icon [class^="flaticon-"]::before {
  position: relative;
  margin: 0;
  color: #fdbe33;
  font-size: 60px;
  line-height: 60px;
  z-index: 3;
}

.feature .feature-text {
  padding-left: 30px;
}

.feature .feature-text h3 {
  margin: 0 0 10px 0;
  color: #fdbe33;
  font-size: 25px;
  font-weight: 600;
}

.feature .feature-text p {
  margin: 0;
  color: #fdbe33;
  font-size: 18px;
  font-weight: 400;
}

.feature .col-md-12:nth-child(2n) [class^="flaticon-"]::before,
.feature .col-md-12:nth-child(2n) h3,
.feature .col-md-12:nth-child(2n) p {
  color: #030f27;
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  position: relative;
  height: 100%;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text a.btn {
  position: relative;
  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #030f27;
  border-radius: 0;
  background: #fdbe33;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #fdbe33;
  background: #030f27;
}

@media (max-width: 767.98px) {
  .about .about-img {
      margin-bottom: 30px;
      height: auto;
  }
}


/*******************************/
/********** Fact CSS ***********/
/*******************************/
.fact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.fact .col-6 {
  display: flex;
  align-items: flex-start;
}

.fact .fact-icon {
  position: relative;
  margin: 7px 15px 0 15px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fact .fact-icon [class^="flaticon-"]::before {
  margin: 0;
  font-size: 60px;
  line-height: 60px;
  background-image: linear-gradient(#ffffff, #fdbe33);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fact .fact-right .fact-icon [class^="flaticon-"]::before {
  background-image: linear-gradient(#ffffff, #030f27);
}

.fact .fact-left,
.fact .fact-right {
  padding-top: 60px;
  padding-bottom: 60px;
}

.fact .fact-text h2 {
  font-size: 35px;
  font-weight: 700;
}

.fact .fact-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.fact .fact-left {
  color: #fdbe33;
  background: #030f27;
}

.fact .fact-right {
  color: #030f27;
  background: #fdbe33;
}

.fact .fact-left h2 {
  color: #fdbe33;
}


/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.service .service-img {
  position: relative;
  overflow: hidden;
}

.service .service-img img {
  width: 100%;
}

.service .service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 15, 39, .7);
  transition: .5s;
  opacity: 0;
}

.service .service-item:hover .service-overlay {
  opacity: 1;
}

.service .service-overlay p {
  margin: 0;
  color: #ffffff;
}

.service .service-text {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-text h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #fdbe33;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-item a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #fdbe33;
  border-radius: 0;
  transition: .3s;
}

.service .service-item:hover a.btn {
  color: #ffffff;
}


/*******************************/
/********** Video CSS **********/
/*******************************/
.video {
  position: relative;
  margin: 45px 0;
  height: 100%;
  min-height: 500px;;
 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #fdbe33;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #fdbe33;
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play:hover:after {
  background-color: darken(#fdbe33, 10%);
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #030f27;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
0% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  opacity: 1;
}
100% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
  opacity: 0;
}
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}


/*******************************/
/*********** Team CSS **********/
/*******************************/



/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.faqs .row {
  position: relative;
}

.faqs .row::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: calc(50% - .5px);
  background: #fdbe33;
}

.faqs #accordion-1 {
  padding-right: 15px;
}

.faqs #accordion-2 {
  padding-left: 15px;
}

@media(max-width: 767.98px) {
  .faqs .row::after {
      display: none;
  }
  
  .faqs #accordion-1,
  .faqs #accordion-2 {
      padding: 0;
  }
  
  .faqs #accordion-2 {
      padding-top: 15px;
  }
}
.faqs .card {
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
}

.faqs .card:last-child {
  margin-bottom: 0;
}

.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  padding: 10px 25px;
  width: 100%;
  color: #121518;
  font-size: 16px;
  line-height: 40px;
  border: 1px solid rgba(0, 0, 0, .1);
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"] {
  background: #fdbe33;
}

.faqs .card-header [data-toggle="collapse"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f067";
  float: right;
  color: #fdbe33;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f068";
  float: right;
  color: #030f27;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-body {
  padding: 20px 25px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-top: none;
}
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.blog .blog-img {
  position: relative;
  overflow: hidden;
}

.blog .blog-img img {
  width: 100%;
}

.blog .blog-title {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 18px;
  font-weight: 700;
  color: #fdbe33;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #fdbe33;
  border-radius: 0;
  transition: .3s;
}

.blog .blog-item:hover a.btn {
  color: #ffffff;
}

.blog .blog-meta {
  position: relative;
  padding: 25px 0 10px 0;
  background: #f3f6ff;
}

.blog .blog-meta::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 1px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #fdbe33;
}

.blog .blog-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  color: #666666;
}

.blog .blog-meta p a {
  margin-left: 5px;
  font-style: normal;
}

.blog .blog-text {
  padding: 10px 25px 25px 25px;
  background: #f3f6ff;
}

.blog .blog-text p {
  margin: 0;
  font-size: 16px;
}

.blog .pagination .page-link {
  color: #030f27;
  border-radius: 0;
  border-color: #fdbe33;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #fdbe33;
  background: #030f27;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}


/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
  position: relative;
  padding: 45px 0;
}

.single .single-content {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single .single-content img {
  margin-bottom: 20px;
  width: 100%;
}

.single .single-tags {
  margin: -5px -5px 41px -5px;
  font-size: 0;
}

.single .single-tags a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .single-tags a:hover {
  color: #fdbe33;
  background: #030f27;
}

.single .single-bio {
  margin-bottom: 45px;
  padding: 30px;
  background: #f3f6ff;
  display: flex;
}

.single .single-bio-img {
  width: 100%;
  max-width: 100px;
}

.single .single-bio-img img {
  width: 100%;
}

.single .single-bio-text {
  padding-left: 30px;
}

.single .single-bio-text h3 {
  font-size: 20px;
  font-weight: 700;
}

.single .single-bio-text p {
  margin: 0;
}

.single .single-related {
  margin-bottom: 45px;
}

.single .single-related h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .related-slider {
  position: relative;
  margin: 0 -15px;
  width: calc(100% + 30px);
}

.single .related-slider .post-item {
  margin: 0 15px;
}

.single .post-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single .post-item .post-img {
  width: 100%;
  max-width: 80px;
}

.single .post-item .post-img img {
  width: 100%;
}

.single .post-item .post-text {
  padding-left: 15px;
}

.single .post-item .post-text a {
  font-size: 16px;
  font-weight: 400;
}

.single .post-item .post-meta {
  display: flex;
  margin-top: 8px;
}

.single .post-item .post-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

.single .post-item .post-meta p a {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
}

.single .related-slider .owl-nav {
  position: absolute;
  width: 90px;
  top: -55px;
  right: 15px;
  display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030f27;
  background: #fdbe33;
  font-size: 16px;
  transition: .3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
  color: #fdbe33;
  background: #030f27;
}

.single .single-comment {
  position: relative;
  margin-bottom: 45px;
}

.single .single-comment h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-list {
  list-style: none;
  padding: 0;
}

.single .comment-child {
  list-style: none;
}

.single .comment-body {
  display: flex;
  margin-bottom: 30px;
}

.single .comment-img {
  width: 60px;
}

.single .comment-img img {
  width: 100%;
}

.single .comment-text {
  padding-left: 15px;
  width: calc(100% - 60px);
}

.single .comment-text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.single .comment-text span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.single .comment-text .btn {
  padding: 3px 10px;
  font-size: 14px;
  color: #030f27;
  background: #dddddd;
  border-radius: 0;
}

.single .comment-text .btn:hover {
  background: #fdbe33;
}

.single .comment-form {
  position: relative;
}

.single .comment-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-form form {
  padding: 30px;
  background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
  margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
  border-radius: 0;
}

.single .comment-form .btn {
  padding: 15px 30px;
  color: #030f27;
  background: #fdbe33;
}

.single .comment-form .btn:hover {
  color: #fdbe33;
  background: #030f27;
}


/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
  position: relative;
  width: 100%;
}

@media(max-width: 991.98px) {
  .sidebar {
      margin-top: 45px;
  }
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  font-size: 30px;
  font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #fdbe33;
}

.sidebar .sidebar-widget .search-widget {
  position: relative;
}

.sidebar .search-widget input {
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar .search-widget input:focus {
  box-shadow: none;
}

.sidebar .search-widget .btn {
  position: absolute;
  top: 6px;
  right: 15px;
  height: 40px;
  padding: 0;
  font-size: 25px;
  color: #fdbe33;
  background: none;
  border-radius: 0;
  border: none;
  transition: .3s;
}

.sidebar .search-widget .btn:hover {
  color: #030f27;
}

.sidebar .sidebar-widget .recent-post {
  position: relative;
}

.sidebar .sidebar-widget .tab-post {
  position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
  color: #fdbe33;
  background: #030f27;
  border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
  color: #030f27;
  background: #fdbe33;
}

.sidebar .tab-post .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.sidebar .tab-post .tab-content .container {
  padding: 0;
}

.sidebar .sidebar-widget .category-widget {
  position: relative;
}

.sidebar .category-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .category-widget ul li {
  margin: 0 0 12px 22px; 
}

.sidebar .category-widget ul li:last-child {
  margin-bottom: 0; 
}

.sidebar .category-widget ul li a {
  display: inline-block;
  line-height: 23px;
}

.sidebar .category-widget ul li::before {
  position: absolute;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fdbe33;
  left: 1px;
}

.sidebar .category-widget ul li span {
  display: inline-block;
  float: right;
}

.sidebar .sidebar-widget .tag-widget {
  position: relative;
  margin: -5px -5px;
}

.single .tag-widget a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .tag-widget a:hover {
  color: #fdbe33;
  background: #030f27;
}

.sidebar .image-widget {
  display: block;
  width: 100%;
  overflow: hidden;
}

.sidebar .image-widget img {
  max-width: 100%;
  transition: .3s;
}

.sidebar .image-widget img:hover {
  transform: scale(1.1);
}



.site-section {
  padding-top: 7rem;
  padding-bottom: 7rem;
  position: relative;
  z-index: 0; }
  @media (max-width: 991.98px) {
    .site-section {
      padding-top: 3rem;
      padding-bottom: 3rem; } }
      
.job-listings {
  padding: 0;
  margin: 0;
  z-index: 0;
  border: 1px solid #eee;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: #fff; }
  .job-listings .badge {
    color: #fff;
    padding: 4px 10px; }
  @media (max-width: 991.98px) {
    .job-listings .custom-width {
      width: 100% !important; } }
  .job-listings .job-listing {
    position: relative;
    border-bottom: 1px solid #eee;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    .job-listings .job-listing:last-child {
      border-bottom: none; }
    .job-listings .job-listing a {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
      .job-listings .job-listing a:hover {
        border-left: 7px solid #89ba16; }
    .job-listings .job-listing .job-listing-logo {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 150px;
      flex: 0 0 150px;
      border-right: 1px solid #eee; }
      @media (max-width: 575.98px) {
        .job-listings .job-listing .job-listing-logo {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%; } }
  .job-listings .job-listing-position h2 {
    font-size: 1.2rem;
    margin-bottom: 0; }
  .job-listings .job-listing-position strong {
    color: #b3b3b3; }
    
.custom-pagination a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  text-align: center;
  margin: 0 5px;
  background: #89ba16;
    color: #fff;
    border-color: #89ba16; 
  text-decoration: none; }
  .custom-pagination a.prev, .custom-pagination a.next {
    width: auto;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px; }
  .custom-pagination a.active, .custom-pagination a:hover {
    background: #808475;
    color: #fff;
    border-color: #808475; }

.border-width-2 {
  border-width: 2px !important; }

.btn-md {
  padding: 12px 20px; }

.slanted:after, .slanted-gray:after {
  content: "";
  background: #fff;
  height: 200px;
  -webkit-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  transform: skewY(-5deg);
  position: absolute;
  left: 0;
  bottom: -100px;
  right: 0;
  z-index: 1; }
.uuuu{
  width: 80px;
  height: 50px;
}


.lklk{
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.lklku{
  width: 200px;
  height: 100px;

}